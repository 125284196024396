import { css } from 'emotion'
import { Colors, Transition } from './constants'

export const ButtonClasses = {
  reset: css`
    transition: ${Transition.standard};
    border: none;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    border-radius: 4px;

    &[disabled] {
      background-color: ${Colors.grey.background}; 
      color: ${Colors.grey.text}; 
    }
  `,
  icon: css`
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    > svg {
      height:20px;
    }
  `,
  primary: css`
    background-color: ${Colors.primary.text};
    color: white;
    background-image: none;
    box-shadow:none;
    transition: ${Transition.standard};

    &:hover, &:active {
      background-color: ${Colors.primary.hover};
      color: white;
      background-image: none;
      border: none;
      box-shadow: none;
    }
    &:active, &:focus {
      // box-shadow: 0 0 0 3px ${Colors.primary.background} !important;
    }
    &[disabled] {
      background-color: ${Colors.primary.text};
      color: white;
      cursor: not-allowed;
    }
    &[disabled]:hover, &[disabled]:focus {
      background-color: ${Colors.grey.background}; 
      color: ${Colors.grey.text};
    }
  `,
  primaryBg: css`
    background-color: ${Colors.primary.background};
    color: ${Colors.primary.text};
    background-image: none;
    box-shadow:none;
    transition: ${Transition.standard};

    &:hover, &:active {
      background-color: ${Colors.primary.background};
      color: white;
      background-image: none;
      border: none;
      box-shadow: none;
    }
  `,
  primaryghost: css`
    background-color: ${Colors.green.background} !important;
    color: ${Colors.green.text} !important;
    background-image: none !important;
    transition: ${Transition.standard};
    border: none;
    box-shadow: none;
    
    

    &:hover, &:active {
      background-color: ${Colors.green.text} !important;
      color: white !important;
      background-image: none !important;
      border: none;
      box-shadow: 0 0 0 3px ${Colors.green.background} !important;
    }
  `,
  ghost: css`
    background-color: transparent !important;
    color: ${Colors.grey.text} !important;
    transition: ${Transition.standard};
    &:hover, &:active {
      transition: ${Transition.standard};
      background-color: ${Colors.grey.background} !important;
    }
  `,
  ghostOnDark: css`
    background-color: transparent !important;
    color: white;
    transition: ${Transition.standard};
    &:hover, &:active {
      transition: ${Transition.standard};
      color: ${Colors.grey.text};
      background-color: ${Colors.grey.background} !important;
    }
  `,
  disable: css`
    background-color: transparent !important;
    cursor: default;
    &:hover, &:active, &:focus {
      background-color: transparent !important;
    }
  `
}