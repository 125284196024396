import { css } from 'emotion'
import { Colors } from './constants'

export const BorderClasses = {
  grey: css`
    border: 1px solid ${Colors.grey.border};
  `,
  topgrey: css`
    border-top: 1px solid ${Colors.grey.border};
  `,
  bottomgrey: css`
    border-bottom: 1px solid ${Colors.grey.border};
  `,
  leftgrey: css`
    border-left: 1px solid ${Colors.grey.border};
  `,
  rightgrey: css`
    border-left: 1px solid ${Colors.grey.border};
  `,
  card: css`
    border-right: 4px solid ${Colors.grey.background};
    border-bottom: 4px solid ${Colors.grey.background};
  `,
  top4: css`
    border-top: 4px solid ${Colors.grey.background};
  `,
  primary: css`
    border-color: ${Colors.primary.text};
  `,
  teal: css`
    border-color: ${Colors.teal.text};
  `

}