import React from "react"
import { cx } from "emotion"
import { Space, Flex, Btn, Form, Typo } from "../../styles"
import { TickSmall, Spinner } from "../icons"
import { validateEmail } from "./utils"
import { Row, Col } from "reactstrap"
import fetch from "unfetch"

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isValid: false,
      hasTyped: false,
      submitting: false,
      success: false
    }
    
    this.updateEmail = this.updateEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  updateEmail (value) {
    if (this.state.hasTyped === false) {
      window.ga('send', 'FormTyped', {
        'eventCategory': 'Category',
        'eventAction': 'Action',
        'eventValue': 30
      });
      this.setState({ hasTyped: true });
    }

    this.setState({ 
      email: value,
      isValid: validateEmail(value)
    });
  }

  async onSubmit () {
    try {
      const email = this.state.email
      this.setState({ submitting: true });
      window.analytics.track('FormSubmit', { email });
      window.ga('send', 'FormSubmit', {
        'eventCategory': 'Category',
        'eventAction': 'Action',
        'eventValue': 100
      });
      const api = await fetch('https://us-central1-prometheus-1148.cloudfunctions.net/website-segmentcom-proxy', {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
        },
        body: JSON.stringify({
          "userId": "019mr8mf4r",
          "event": "DemoRequest",
          "properties": {
            "email": email,
            "formIndex": this.props.formIndex || 0,
            "name": "Real-Time Sales Website",
            "title": "RTS",
            "category": "Demo",
            "label": "Submit",
            "value": 5
          }
        })
      })
      const res = await api.json()
      this.setState({ success: true });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  }

  render () {
    const {
      withDetail = true,
      onDark = false
    } = this.props;

    return (
      <React.Fragment>
        <div className={cx(Flex.hcl)}>
          <input 
            type="email" 
            className={cx(Form.input)}
            style={{height:50,minWidth:250}} 
            placeholder={"Your company email..."}
            value={this.state.email} 
            onChange={e => this.updateEmail(e.target.value)} />
          <button 
            disabled={!this.state.isValid || this.state.submitting}
            onClick={() => this.onSubmit()} 
            className={cx(Btn.reset, Btn.primary, Space.pl3, Space.pr3, Space.ml1)} style={{height: 50,lineHeight:"1em"}}>
            {this.state.submitting ? 
              (<span style={{paddingLeft:30,paddingRight:30}}><Spinner style={{ height:20 }} /></span>):(<span>Get started for free</span>)}
          </button>
        </div>
        { withDetail && 
          <div className={cx(Flex.hcl, Typo.small, Space.mt2, Typo.muted)}>
            <TickSmall style={{height:18,width:18,marginBottom:2}} /><div className={cx(Space.ml1,Space.mr3)} style={{lineHeight:"1em"}}>14-day trial</div>
            <TickSmall style={{height:18,width:18,marginBottom:2}} /><div className={cx(Space.ml1,Space.mr3)} style={{lineHeight:"1em"}}>No credit card required</div>
            <TickSmall style={{height:18,width:18,marginBottom:2}} /><div className={cx(Space.ml1,Space.mr3)} style={{lineHeight:"1em"}}>All features included</div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default SignUp