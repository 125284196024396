import { css } from 'emotion'
import { Colors } from './constants'

export const GridClasses = {
  grid: css`
    display: grid;
    .cell {
      padding: 8px 4px;
    }
  `,
  headerRow: css`
    font-weight: 400;
    border-bottom: 1px solid ${Colors.grey.border};
  `,
  searchHeader: css`
    display:grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 2px solid ${Colors.grey.border};
    > div {
      padding: 0px 12px;
    }
    > div:first-of-type {
      padding-left:25px;
    }
  `,
  equal3: css`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
  `,
  equal2: css`
    display:grid;
    grid-template-columns: 1fr 1fr;
  `,
  equal4: css`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `,
  equal5: css`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `,

  useCaseGrid: css`
    display:grid;
    grid-template-columns: 200px 1fr 1fr;
  `,
  signUpGrid: css`
    display:grid;
    grid-template-columns: 3fr 2fr;
  `,
  workflowGrid: css`
    display:grid;
    grid-template-columns: 1fr 170px 1fr;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 100px 1fr;
    }
  `,
  analyticsGrid: css`
    display:grid;
    grid-template-columns: 1fr 200px 1fr;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 150px 1fr;
    }
  `
}

// grid-template-columns: 3rem 25% 1fr 2fr
// grid-template-columns: minmax(auto, 50%) 1fr 3em;