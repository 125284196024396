import { css } from 'emotion';
export const FlexClasses = {
  hcc: css`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  `,
  hcl: css`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  `,
  hcr: css`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  `,
  hcj: css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  `,
  htj: css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: top;
  `,
  vcc: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  vsl: css`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
  `,
  vsc: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
  `
}