import { css } from 'emotion'
import { Colors,Transition } from './constants'

export const FormClasses = {
  input: css`
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    border: 2px solid ${Colors.grey.border};
    padding: 8px 10px;
    background-color: white;
    color: ${Colors.text.default};
    transition: ${Transition.fast};
    &:focus {
      box-shadow: 0 0 0 3px ${Colors.primary.background} !important;
      border:2px solid ${Colors.primary.text};
      transition: ${Transition.fast};
    }
    &::placeholder {
      color: ${Colors.grey.text};
    }
  `,
  textarea: css`
    box-shadow:none !important;
    border: 2px solid ${Colors.grey.border};
    resize: none;
    &:focus {
      box-shadow: 0 0 0 3px ${Colors.green.background} !important;
      border:2px solid ${Colors.green.text};
    }
    &::placeholder {
      color: ${Colors.grey.text};
    }
  `,
  tabs: css`
    background-image:none;
    box-shadow:none;
    > div {
      background-image:none;
      box-shadow:none;
      background-color: ${Colors.grey.background};
      transition: ${Transition.standard};
      color: ${Colors.grey.text};
      &:hover {
        background-image:none !important;
        box-shadow:none;
        color: ${Colors.green.text} !important;
        label {
          color: ${Colors.green.text} !important;
        }
      }

      &[data-active="true"] {
        background-color: ${Colors.green.background} !important;
        box-shadow: none !important;
        label {
          color: ${Colors.green.text} !important;
        }
      }
    }
    label {
      transition: ${Transition.standard};
      background-image:none;
      box-shadow:none;
    }
  `,
}