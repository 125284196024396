import { css } from "emotion"
import { Transition } from "./constants"

const InteractiveClasses = {
  expand: css`
    max-height: 0px;
    overflow: hidden;
    transition: ${Transition.standard} ease-in;
  `,
  expandActive: css`
    max-height: 400px;
    overflow: hidden;
    transition: ${Transition.standard} ease-in;
  `
}