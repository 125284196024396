import React from "react"

export const ArrowLeft = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}>
    <path d="M13.99 6.99H4.41L7.7 3.7a1.003 1.003 0 0 0-1.42-1.42l-5 5a1.014 1.014 0 0 0 0 1.42l5 5a1.003 1.003 0 0 0 1.42-1.42L4.41 8.99H14c.55 0 1-.45 1-1s-.46-1-1.01-1z" 
      fillRule="evenodd"></path>
    </svg>
  )
}

export const ArrowRight = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M14.7 7.29l-5-5a.965.965 0 0 0-.71-.3 1.003 1.003 0 0 0-.71 1.71l3.29 3.29H1.99c-.55 0-1 .45-1 1s.45 1 1 1h9.59l-3.29 3.29a1.003 1.003 0 0 0 1.42 1.42l5-5c.18-.18.29-.43.29-.71s-.12-.52-.3-.7z" fillRule="evenodd"></path></svg>
  )
}

export const ChevronDown = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 0 0-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0 0 12 5z" fillRule="evenodd"></path></svg>
  )
}
export const CaretRight = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M11 8c0-.15-.07-.28-.17-.37l-4-3.5A.495.495 0 0 0 6 4.5v7a.495.495 0 0 0 .83.37l4-3.5c.1-.09.17-.22.17-.37z" fillRule="evenodd" /></svg>
  )
}

export const TickMark = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M14 3c-.28 0-.53.11-.71.29L6 10.59l-3.29-3.3a1.003 1.003 0 0 0-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l8-8A1.003 1.003 0 0 0 14 3z" fillRule="evenodd" /></svg>
  )
}
export const TickSmall = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z" fillRule="evenodd" /></svg>
  )
}
export const Etc = ({
  color = "currentColor",
  style = {}
}) => (
  <svg viewBox="0 0 17 17" style={Object.assign({},{fill: color},style)}>
  <g transform="translate(1.000000, 6.200000)"><circle cx="2" cy="2" r="2" /><circle cx="13" cy="2" r="2" /><circle cx="7.5" cy="2" r="2" /></g>
  </svg>
)

export const Flash = ({
  color = "currentColor",
  style = {}
}) => (
  <svg viewBox="0 0 17 17" style={Object.assign({},{fill: color},style)}>
    <g strokeWidth="1" fillRule="evenodd"><polygon points="6.5 16 7.75 9.75 4 9.75 11.5 1 10.25 7.25 14 7.25" /></g>
  </svg>
)

export const Task = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M8 16c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm4-11c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z" fillRule="evenodd" /></svg>
  )
}

export const Phone = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 16 16" style={Object.assign({},{fill: color},style)}><path d="M15.9 12.41c-.06-.06-3.37-2-3.48-2.05a.794.794 0 0 0-.32-.08c-.15 0-.34.11-.57.32-.23.22-.94 1.19-1.15 1.4-.21.22-.38.32-.52.32-.07 0-.15-.02-.25-.06-.1-.04-1.16-.58-3.36-2.52-2.2-1.93-2.49-3.2-2.5-3.55 0-.14.11-.31.32-.52.22-.21.45-.41.7-.6.25-.19.49-.4.7-.62.22-.23.32-.42.32-.57 0-.11-.03-.21-.08-.32C5.66 3.46 3.66.15 3.59.08 3.44-.07 2.85 0 2.55.16.16 1.46-.03 3.2 0 3.89c.04.71.49 4.46 4.16 7.95C8.72 16.17 11.89 16 12.1 16c.69 0 2.82-.38 3.72-2.55.13-.32.25-.87.08-1.04z" fillRule="evenodd" /></svg>
  )
}

export const Mail = ({
  color = "currentColor",
  style = {}
}) => {
  return (
    <svg viewBox="0 0 19 19" style={Object.assign({},{fill: color},style)}>
      <g transform="translate(0.000000, 3.000000)" fillRule="nonzero">
        <path d="M1,0 L18,0 C18.5522847,-1.01453063e-16 19,0.44771525 19,1 L19,13 C19,13.5522847 18.5522847,14 18,14 L1,14 C0.44771525,14 6.76353751e-17,13.5522847 0,13 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M9.37192057,7.06038309 L3.39460693,3.36220272 C3.04236122,3.14426733 2.58013811,3.25314736 2.36220272,3.60539307 C2.14426733,3.95763878 2.25314736,4.41986189 2.60539307,4.63779728 L9.3631352,8.8188309 L16.2678104,4.64170833 C16.6222161,4.42730344 16.7357092,3.96619132 16.5213043,3.6117856 C16.3068994,3.25737988 15.8457873,3.14388678 15.4913816,3.35829167 L9.37192057,7.06038309 Z" id="Combined-Shape"></path>
      </g>
    </svg>
  )
}

export const LinkedIn = ({
  color = "",
  style = {}
}) => (
  <svg viewBox="0 0 18 18" style={Object.assign({},{fill: color},style)}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M16.7299931,11.1833818 L16.7299931,16.9658182 L13.3585463,16.9658182 L13.3585463,11.5706545 C13.3585463,10.2153455 12.8709029,9.2904 11.6505509,9.2904 C10.7189669,9.2904 10.1645806,9.91374545 9.92068571,10.5167273 C9.831744,10.7322909 9.80885029,11.0323273 9.80885029,11.3340727 L9.80885029,16.9658182 L6.43626971,16.9658182 C6.43626971,16.9658182 6.48169143,7.82818182 6.43626971,6.88145455 L9.80852114,6.88145455 L9.80852114,8.31087273 C9.80168229,8.32156364 9.79279543,8.33309091 9.78635886,8.34345455 L9.80852114,8.34345455 L9.80852114,8.31087273 C10.2565577,7.62472727 11.056704,6.64454545 12.84768,6.64454545 C15.0665783,6.64450909 16.7299931,8.08585455 16.7299931,11.1833818 Z M2.90840686,2.02029091 C1.75465143,2.02029091 1,2.77276364 1,3.76229091 C1,4.73025455 1.73281829,5.50556364 2.86364343,5.50556364 L2.88617143,5.50556364 C4.062272,5.50556364 4.79370057,4.7304 4.79370057,3.76229091 C4.77153829,2.77276364 4.062272,2.02029091 2.90840686,2.02029091 Z M1.20030171,16.9658182 L4.57160229,16.9658182 L4.57160229,6.88145455 L1.20030171,6.88145455 L1.20030171,16.9658182 Z" fill="#0077b5" fillRule="nonzero"></path>
    </g>
  </svg>
)

export const DeeploopOcta = ({
  color = "currentColor",
  style = {}
}) => (
  <svg width="96px" height="96px" viewBox="0 0 96 96" style={Object.assign({},{fill: color},style)}>
    <g fillRule="nonzero">
      <path d="M68.0853682,3 C54.4698944,3 43.3552219,14.0220502 43.3552219,27.7301463 L43.3552219,47.4586899 C39.1872197,44.402155 34.0929948,42.5497096 28.5356586,42.5497096 C14.9201848,42.5497096 3.80551231,53.5717598 3.80551231,67.2798559 C3.80551231,80.8953297 14.8275625,92.0100021 28.5356586,92.0100021 C42.2437546,92.0100021 53.2658049,80.9879519 53.2658049,67.2798559 L53.2658049,47.4586899 C57.433807,50.5152249 62.5280319,52.3676703 68.0853682,52.3676703 C81.700842,52.3676703 92.8155144,41.3456201 92.8155144,27.637524 C92.8155144,13.9294279 81.7934642,3 68.0853682,3 Z M43.4478442,67.1872336 C43.4478442,75.3379934 36.7790407,82.0067969 28.6282809,82.0067969 C20.477521,82.0067969 13.8087175,75.3379934 13.8087175,67.1872336 C13.8087175,59.0364738 20.477521,52.3676703 28.6282809,52.3676703 C31.406949,52.3676703 34.0929948,53.2012707 36.3159293,54.5906048 L43.4478442,47.4586899 L43.4478442,67.1872336 Z M68.0853682,42.5497096 C65.3067,42.5497096 62.6206542,41.7161091 60.3977197,40.3267751 L53.2658049,47.4586899 L53.2658049,27.7301463 C53.2658049,19.5793865 59.9346083,12.910583 68.0853682,12.910583 C76.236128,12.910583 82.9049315,19.5793865 82.9049315,27.7301463 C82.9049315,35.8809061 76.3287503,42.5497096 68.0853682,42.5497096 Z" />
    </g>
  </svg>
)



export const Analytics1 = ({
  color = "currentColor",
  style = {}
}) => (
  <svg viewBox="0 0 17 17" style={Object.assign({},{fill: color},style)}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <path d="M1,0 L16,0 C16.5522847,-1.01453063e-16 17,0.44771525 17,1 L17,16 C17,16.5522847 16.5522847,17 16,17 L1,17 C0.44771525,17 6.76353751e-17,16.5522847 0,16 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M6.94694683,9.20893485 L10.1817942,11.0023437 C10.6190834,11.2447781 11.1682285,11.1269745 11.4676018,10.7265099 L15.3009351,5.59875112 C15.6316163,5.15640686 15.5410954,4.52974598 15.0987511,4.19906486 C14.6564069,3.86838375 14.029746,3.95890461 13.6990649,4.40124888 L10.3882727,8.8300168 L7.31820575,7.12796272 C6.95740994,6.92793647 6.51081118,6.96999564 6.19370346,7.23386456 L2.36037013,10.4236246 C1.9358382,10.7768825 1.87805913,11.4074056 2.23131695,11.8319376 C2.58457477,12.2564695 3.21509794,12.3142486 3.63962987,11.9609907 L6.94694683,9.20893485 Z" />
    </g>
  </svg>
)

export const Analytics2 = ({
  color = "currentColor",
  style = {}
}) => (
  <svg viewBox="0 0 17 17" style={Object.assign({},{fill: color},style)}>
    <g strokeWidth="1">
        <path d="M1,0 L16,0 C16.5522847,-1.01453063e-16 17,0.44771525 17,1 L17,16 C17,16.5522847 16.5522847,17 16,17 L1,17 C0.44771525,17 6.76353751e-17,16.5522847 0,16 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M3,6 L3,15 L5.5,15 L5.5,6 L3,6 Z M7.5,3 L7.5,15 L10,15 L10,3 L7.5,3 Z M12,8 L12,15 L14.5,15 L14.5,8 L12,8 Z"></path>
    </g>
  </svg>
)





export const Spinner = ({
  color = "#fff",
  style = {}
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={style.width || 38} height={style.height || 38} viewBox="0 0 38 38">
    <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop stop-color={color} stopOpacity="0" offset="0%"/>
            <stop stop-color={color} stopOpacity=".631" offset="63.146%"/>
            <stop stop-color={color} offset="100%"/>
        </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
            <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2" transform="rotate(199.984 18 18)">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/>
            </path>
            <circle fill={color} cx="36" cy="18" r="1" transform="rotate(199.984 18 18)">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/>
            </circle>
        </g>
    </g>
  </svg>
)