import { css } from "emotion"
import { Colors } from "./constants"

export const SpeechClasses = {
  boxLeft: css`
    position: relative;
    background: ${Colors.primary.background};
    color: ${Colors.primary.text};
    border-radius: .4em;
    padding:15px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-right-color: ${Colors.primary.background};
      border-left: 0;
      border-top: 0;
      margin-top: -4px;
      margin-left: -8px;
    }
  `,
  boxRight: css`
    position: relative;
    background: #00aabb;
    border-radius: .4em;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 18px solid transparent;
      border-left-color: #00aabb;
      border-right: 0;
      border-top: 0;
      margin-top: -9px;
      margin-right: -18px;
    }
  `
}