import { css } from 'emotion'
import { Colors } from './constants'

export const MapStyleClasses = {
  Marker: css`
    background-color: ${Colors.dark.background};
    width:34px;
    height:34px;
    display:flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-radius: 50px 50px 50px 0px;
    transform: rotate(-45deg);
  `,
}