import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.sizes;
      return (
        <Img
          alt={props.alt}
          sizes={imageSizes}
        />
      );
    }}
  />
)

export default Image;


// //imports
// const HomePage = ({ data }) => {  
//   const siteTitle = data.site.siteMetadata.title;
//   console.log(data.ProjectImgs);
//   const { edges: projectImgData } = data.ProjectImgs;
//   const { edges: iconImgData } = data.iconImgs;
//   return (
//     <div>     
//       <Helmet      title={siteTitle}      link={[{ rel: "icon", type: "image/png", href: `${favicon}`}]}     />     
//       <Cover coverImg={data.coverImg} />     
//       <div className="container-fluid main">      
//       <Navigation />      
//       <AboutMe profileImg={data.profileImg} iconImgs={iconImgData}       />                     &lt;
//     Projects projectImgs={projectImgData} />     
//     <Contacts />     
//     <Footer />     
//     </div>    
//     </div>  
//   );
// };

// export const query = graphql`  
//   query allImgsQuery {    
//     ProjectImgs: allFile(      
//       sort: { order: ASC, fields: [absolutePath] }      
//       filter: { relativePath: { regex: "/projects/.*.png/" } }) 
//       {      
//         edges {        
//           node {          
//             relativePath          
//               name          
//                 childImageSharp {            
//                   sizes(maxWidth: 320) {              
//                     ...GatsbyImageSharpSizes            
//                   }          
//                 }        
//               }      
//             }    
//           }
//   }`;






