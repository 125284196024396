import { css } from 'emotion';
import { Colors } from './constants';

export const CardClasses = {
  body: css`
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 3px 3px rgba(18,66,74,0.25);
  `,
  tableHeader: css`
    margin: 0px;
    padding: 8px 15px;
    border-bottom: 1px solid ${Colors.grey.border};
    font-weight: 400;
  `,
  tableBody: css`
    padding:8px 15px;
    .row {
      border-bottom: 1px dotted ${Colors.grey.border};
      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: ${Colors.grey.backround};
        }
        .highlight {
          color: ${Colors.green.text};
        }
      }
    }
  `,
  clickBody: css`
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 3px 3px rgba(18,66,74,0.3);
    transition:0.3s ease-in-out;
    border-left: 3px solid transparent;
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    cursor: pointer;

    &:hover {
      transition:0.3s ease-in-out;
      transform: translateY(-5px);
      box-shadow: 0 3px 10px rgba(18,66,74,0.3);
      padding-left: 15px;
      border-left: 3px solid ${Colors.primary.text};
    }
  `,
  lightBody: css`
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px;
  `
}