export const Theme = {
  blue: {
    tint1: "#0053d6", // Primary Light
    tint2: "#14234b", // Dark
    tint3: "", // Ultra-light BG
    tint4: "#123693", // Dark texts
  },
  yellow: {
    tint1: "#ffdb13",
    tint2: "#ffeacc"
  },
  red: {
    tint1: "#e1144b",
    tint2: "#ffe6f0",
    tint3: "#ff617b", // Icon
  },
  orange: {
    tint1: "#ff8a4f",
    tint2: "#ffeacc",
    tint3: "#ff5f19"
  },
  green: {
    tint1: "#33e0c8",
    tint2: "#d4ffed", // Light BG
  },
  purple: {
    tint1: "#ad70ff", // Primary icon
    tint2: "#f0ebff", // Ultra-light BG
  },
  teal: {
    tint1: "#49ebd0", // Primary icon
  },
  gray: {
    tint1: "#93a4d2", // Muted text
    tint2: "#f0f5f5", // Background
    tint3: "#d8d8d8", // Borders
  }
}


export const Colors = {
  text: {
    default: '#123693'
  },
  primary: {
    main: '#0053d6',
    hover: '#003FA1',
    text: '#0053d6',
    darker: '#14234b',
    background: 'rgba(20,71,216,0.15)',
  },
  green: {
    background: '#d4ffed',
    text: '#33e0c8',
    chart: '#2AC955',
    darker: '#109C75'
  },
  teal: {
    text: "#49ebd0"
  },
  purple: {
    text: "#ad70ff"
  },
  yellow: {
    text: '#ffdb13', //'#FFAC4A',
    hovered: '#FFA02F',
    background: "#fff4cc"
  },
  red: {
    chart: 'rgb(242, 99, 73)',
    text: '#e1144b',
    background: "#ffe6f0"
  },
  orange: {
    text: "#ff8a4f",
    background: "#ffeacc"
  },
  offred: {
    text: '',
    background: '#FF5777'
  },
  blue: {
    chart: '#68DBF4'
  },
  dark: {
    background: '#14234b',
    altBackground: '#172B5F',
    text: 'white',
    fadedText: '#6E7D8B',
  },
  grey: {
    elementBg: '#E6EAF3',
    elementText: '#7D88A0',
    border: '#EAEEF5',
    faded: '#93a4d2',
    text: '#93a4d2',
    // background: '#EBEFF2',
    background: '#f0f5f5',
    lightbackground: '#EDEFF4',
    loadingbackground: '#D6DAE3'
  },
  background: {
    tint1: "#f0f5f5", // Grey (primary)
    tint2: "#33e0c8",
    tint3: "#d4ffed",
    tint4: "#f0ebff",
    tint5: "#ffe6f0", // Red
    tint6: "#ffeacc", // Orange
    tint7: "#f0ebff", // Purple
  }
}

export const Transition = {
  link: '0.3s',
  fast: '0.2s',
  standard: '0.3s'
}

export const BorderRadius = {
  bigElements: "2px",
  smallElements: "4px"
}