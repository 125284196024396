import { css } from 'emotion';

export const Spacing = {
  section: css`
    padding-top:100px;
    padding-bottom:100px;
    padding-left:60px;
    padding-right:60px;
    position:relative;
    overflow: hidden !important;

    @media (max-width: 768px) {
      padding-left:15px;
      padding-right:15px;
    }
  `,
  smallSection: css`
    padding-top:40px;
    padding-bottom:40px;
    padding-left:70px;
    padding-right:70px;
    position:relative;
    overflow: hidden !important;

    @media (max-width: 768px) {
      padding-left:15px;
      padding-right:15px;
    }
  `,
  hero: css`
    padding-top:260px;
    padding-bottom:170px;
    padding-left:70px;
    padding-right:70px;
    position:relative;
    overflow: hidden !important;

    @media (max-width: 768px) {
      padding-left:15px;
      padding-right:15px;
    }
  `,
  m1: css`
    margin: 5px;
  `,
  m2: css`
    margin: 10px;
  `,
  m3: css`
    margin: 15px;
  `,
  m4: css`
    margin: 25px;
  `,
  mr1: css`
    margin-right: 5px;
  `,
  mr2: css`
    margin-right: 10px;
  `,
  mr3: css`
    margin-right: 15px;
  `,
  mr4: css`
    margin-right: 25px;
  `,
  ml1: css`
    margin-left: 5px;
  `,
  ml2: css`
    margin-left: 10px;
  `,
  ml3: css`
    margin-left: 15px;
  `,
  ml4: css`
    margin-left: 25px;
  `,
  mt1: css`
    margin-top: 5px;
  `,
  mt2: css`
    margin-top: 10px;
  `,
  mt3: css`
    margin-top: 15px;
  `,
  mt4: css`
    margin-top: 25px;
  `,
  mb0: css`
    margin-bottom: 0px;
  `,
  mb1: css`
    margin-bottom: 5px;
  `,
  mb2: css`
    margin-bottom: 10px;
  `,
  mb3: css`
    margin-bottom: 15px;
  `,
  mb4: css`
    margin-bottom: 25px;
  `,
  mb5: css`
    margin-bottom: 35px;
  `,

  p1: css`
    padding: 5px;
  `,
  p2: css`
    padding: 10px;
  `,
  p3: css`
    padding: 15px;
  `,
  p4: css`
    padding: 25px;
  `,
  p5: css`
    padding: 35px;
  `,
  p6: css`
    padding: 45px;
    @media (max-width: 768px) {
      padding: 45px 20px;
    }
  `,
  pl1: css`
    padding-left: 5px;
  `,
  pl2: css`
    padding-left: 10px;
  `,
  pl3: css`
    padding-left: 15px;
  `,
  pl4: css`
    padding-left: 25px;
  `,
  pl5: css`
    padding-left: 35px;
  `,
  pr1: css`
    padding-right: 5px;
  `,
  pr2: css`
    padding-right: 10px;
  `,
  pr3: css`
    padding-right: 15px;
  `,
  pr4: css`
    padding-right: 25px;
  `,
  pr5: css`
    padding-right: 35px;
  `,
  pt1: css`
    padding-top: 5px;
  `,
  pt2: css`
    padding-top: 10px;
  `,
  pt3: css`
    padding-top: 15px;
  `,
  pt4: css`
    padding-top: 25px;
  `,
  pb1: css`
    padding-bottom: 5px;
  `,
  pb2: css`
    padding-bottom: 10px;
  `,
  pb3: css`
    padding-bottom: 15px;
  `,
  pb4: css`
    padding-bottom: 25px;
  `,
  testimonialTitle: css`
    margin-bottom: 60px;
    @media (max-width: 768px) {
      margin-bottom: 200px;
    }
  `,
  testimonialCardBody: css`
    padding-top:45px;
    padding-bottom:45px;
    @media (max-width: 768px) {
      padding-top:220px;
      padding-bottom:80px;
    }
  `
}