import { css } from 'emotion';
import { Colors } from './constants';

export const TagClasses = {
  tag: css`
    padding: 5px 8px;
    margin: 0px 5px;
    background-color: ${Colors.grey.background};
    color: ${Colors.grey.text};
    border-radius: 3px;
    
    &:first-of-type {
      margin-left:0px;
    }
  `,
  primary: css`
    background-color: ${Colors.green.background};
    color: ${Colors.green.text};
  `
}