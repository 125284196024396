import { css } from 'emotion';
import { Colors, Transition } from './constants'

export const TypoClasses = {
  bold: css`
    font-weight:600;
  `,
  medium: css`
    font-weight:400;
  `,
  light: css`
    font-weight: 300;
  `,
  h1: css`
    font-size: 25px;
  `,
  h2: css`
    font-size: 21px;
  `,
  h3: css`
    font-size: 18px;
  `,
  huge: css`
    font-size: 28px;
    line-height: 38px;
  `,
  giga: css`
    font-size: 50px;
    line-height: 60px;
  `,
  reg: css`
    font-size: 16px;
  `,
  med: css`
    font-size: 14px;
  `,
  small: css`
    font-size: 13px;
  `,
  tiny: css`
    font-size:10px;
  `,
  cap: css`
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 800;
  `,

  primary: css`
    color: ${Colors.primary.text};
  `,
  muted: css`
    color: ${Colors.grey.text};
  `,
  green: css`
    color: ${Colors.green.text};
  `,
  teal: css`
    color: ${Colors.teal.text};
  `,
  red: css`
    color: ${Colors.red.text};
  `,
  orange: css`
    color: ${Colors.orange.text};
  `,
  yellow: css`
    color: ${Colors.yellow.text};
  `,
  darkmuted: css`
    color: rgba(255,255,255,0.6);
  `,
  standard: css`
    color: ${Colors.text.default};
  `,
  purple: css`
    color: ${Colors.purple.text};
  `,
  white: css`
    color: white;
  `,

  quote: css`
    position:relative;
    &:before {
      position:absolute;
      left:-15px;
      content:'"';
      top: 0px;
    }
    &:after {
      margin-left:4px;
      content:'"';
    }
  `,

  sans: css`
    font-family: 'IBM Plex Sans', sans-serif;
  `,
  serif: css`
    font-family: 'Merriweather', serif;
  `,
  fancyu: css`

  `,

  inlineLink: css`
    color: ${Colors.green.text};
    text-decoration: underline dotted ${Colors.green.text} !important;
    transition: ${Transition.standard};
    &:hover {
      color: ${Colors.green.darker};
      text-decoration: underline dotted ${Colors.green.darker} !important;
    }
  `
}