import { css } from "emotion"
import { Colors, Transition } from "./constants"

export const LinkStyles = {
  onDark: css`
    color: white;
    outline: none !important;
    text-decoration: none !important;
    opacity: 0.7;
    transition: ${Transition.standard};
    
    &:hover, &:focus {
      opacity: 1;
      transition: ${Transition.standard};
    }
  `,
  tabSelect: css`
    cursor: pointer;
    font-size: 1.15em;
    padding: 12px 0px;
    transition: ${Transition.standard};
    color: ${Colors.grey.text};

    &:hover {
      transition: ${Transition.standard};
      color: ${Colors.primary.text};
    }
    &.active {
      transition: ${Transition.standard};
      color: ${Colors.primary.text};
    }
    
    .title {
      border-left: 3px solid transparent;
      margin-left: -13px;
      padding-left: 10px;
    }
    .expand {
      max-height: 0px;
      overflow: hidden;
      transition: ${Transition.standard};
    }
    .rotate {
      transform: rotate(0deg) translateX(0px) translateY(0px);
      transition: ${Transition.standard};
    }
    &.active {
      .expand {
        max-height: 150px;
        transition: ${Transition.standard};
      }
      .rotate {
        transform: rotate(90deg) translateX(-3px) translateY(-2px);
      }
      .title {
        border-left: 3px solid ${Colors.teal.text};
      }
    }    
  `
}