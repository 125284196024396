import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./imageVar"
import { cx } from "emotion"
import { Nav, Flex, Space, Btn, Typo } from "../styles"

export const SignUpButton = ({
  color = "standard"
}) => (
  <React.Fragment>
    <a href="https://app.deeploop.com">
      <button style={{height:42}} className={cx(Btn.reset, Btn.ghostOnDark, Space.pl3, Space.pr3)}>Login</button>
    </a>
    <Link to="/signup">
      <button style={{height:42}} className={cx(Btn.reset, Btn.primary, Space.pl3, Space.pr3, Space.ml2)}>Sign up</button>
    </Link>
  </React.Fragment>
)

const Header = ({ siteTitle, headerType = "standard" }) => {
  if (headerType === "standard") {
    return (
      <header className={cx(Nav.navbar, Flex.hcj, Space.pl4, Space.pr4)}>
        <div className={cx(Flex.hcl)}>
          <Link to="/">
            <svg height="37px" viewBox="0 0 481 97" style={{marginLeft:10}}>
              <g fillRule="nonzero">
                <g transform="translate(118.000000, 21.000000)" fill="#FFFFFF">
                  <path d="M36.1,61.6 L36.1,54.2 C32.8,58.8 28.2,62.5 20.9,62.5 C10.5,62.5 0.3,54.2 0.3,39.4 C0.3,24.6 10.3,16.3 20.9,16.3 C28.2,16.3 32.9,20 36.1,24.1 L36.1,0.3 L46.3,0.3 L46.3,61.5 L36.1,61.5 L36.1,61.6 Z M23.4,25.2 C16.4,25.2 10.6,30.6 10.6,39.5 C10.6,48.2 16.5,53.8 23.4,53.8 C30.2,53.8 36.3,48.2 36.3,39.5 C36.3,30.8 30.2,25.2 23.4,25.2 Z"></path>
                  <path d="M76.9,54.3 C81.9,54.3 85.4,52.5 88.9,49 L94.9,54.3 C90.7,59.3 84.9,62.6 76.7,62.6 C63.8,62.6 53.9,53.3 53.9,39.5 C53.9,26.7 62.9,16.3 75.6,16.3 C89.7,16.3 96.9,27.4 96.9,40.2 C96.9,41.1 96.8,42 96.7,43 L64,43 C65.2,50.3 70.3,54.3 76.9,54.3 Z M86.8,36.3 C86.1,29.8 82.3,24.6 75.5,24.6 C69.3,24.6 64.9,29.4 64,36.3 L86.8,36.3 Z"></path>
                  <path d="M125,54.3 C130,54.3 133.5,52.5 137,49 L143,54.3 C138.8,59.3 133,62.6 124.8,62.6 C111.9,62.6 102,53.3 102,39.5 C102,26.7 111,16.3 123.7,16.3 C137.8,16.3 145,27.4 145,40.2 C145,41.1 144.9,42 144.8,43 L112.1,43 C113.4,50.3 118.4,54.3 125,54.3 Z M134.9,36.3 C134.2,29.8 130.4,24.6 123.6,24.6 C117.4,24.6 113,29.4 112.1,36.3 L134.9,36.3 Z"></path>
                  <path d="M177.9,62.5 C170.6,62.5 165.9,58.8 162.8,54.7 L162.8,75 L152.6,75 L152.6,17.3 L162.8,17.3 L162.8,24.7 C166.1,20.1 170.7,16.4 177.9,16.4 C188.4,16.4 198.6,24.7 198.6,39.5 C198.6,54.2 188.5,62.5 177.9,62.5 Z M175.5,25.2 C168.6,25.2 162.5,30.8 162.5,39.5 C162.5,48.1 168.6,53.8 175.5,53.8 C182.5,53.8 188.2,48.3 188.2,39.5 C188.2,30.7 182.4,25.2 175.5,25.2 Z"></path>
                  <polygon points="208.4 61.6 208.4 0.4 212.5 0.4 212.5 61.6"></polygon>
                  <path d="M242.4,62.6 C229.7,62.6 220.4,52.4 220.4,40.4 L220.4,40.2 C220.4,28.2 229.7,17.8 242.5,17.8 C255.2,17.8 264.5,28 264.5,40 L264.5,40.2 C264.5,52.2 255.2,62.6 242.4,62.6 Z M260,40.2 C260,29.9 252.3,21.7 242.3,21.7 C232.1,21.7 224.8,30 224.8,40.1 L224.8,40.3 C224.8,50.6 232.5,58.8 242.5,58.8 C252.7,58.8 260,50.5 260,40.4 L260,40.2 Z"></path>
                  <path d="M290.9,62.6 C278.2,62.6 268.9,52.4 268.9,40.4 L268.9,40.2 C268.9,28.2 278.2,17.8 291,17.8 C303.7,17.8 313,28 313,40 L313,40.2 C313,52.2 303.6,62.6 290.9,62.6 Z M308.5,40.2 C308.5,29.9 300.8,21.7 290.8,21.7 C280.6,21.7 273.3,30 273.3,40.1 L273.3,40.3 C273.3,50.6 281,58.8 291,58.8 C301.2,58.8 308.5,50.5 308.5,40.4 L308.5,40.2 Z"></path>
                  <path d="M342.1,62.6 C333.5,62.6 328,57.7 324.4,52.4 L324.4,75 L320.3,75 L320.3,18.8 L324.4,18.8 L324.4,28.4 C328.1,22.8 333.6,17.8 342.1,17.8 C352.5,17.8 363,26.1 363,40 L363,40.2 C363,54.1 352.5,62.6 342.1,62.6 Z M358.5,40.2 C358.5,29 350.7,21.7 341.7,21.7 C332.8,21.7 324.2,29.2 324.2,40.1 L324.2,40.3 C324.2,51.3 332.8,58.7 341.7,58.7 C351,58.7 358.5,51.9 358.5,40.4 L358.5,40.2 Z"></path>
                </g>
                <path d="M70.3,0 C55.6,0 43.6,11.9 43.6,26.7 L43.6,48 C39.1,44.7 33.6,42.7 27.6,42.7 C12.9,42.7 0.9,54.6 0.9,69.4 C0.9,84.1 12.8,96.1 27.6,96.1 C42.4,96.1 54.3,84.2 54.3,69.4 L54.3,48 C58.8,51.3 64.3,53.3 70.3,53.3 C85,53.3 97,41.4 97,26.6 C97,11.8 85.1,0 70.3,0 Z M43.7,69.3 C43.7,78.1 36.5,85.3 27.7,85.3 C18.9,85.3 11.7,78.1 11.7,69.3 C11.7,60.5 18.9,53.3 27.7,53.3 C30.7,53.3 33.6,54.2 36,55.7 L43.7,48 L43.7,69.3 Z M70.3,42.7 C67.3,42.7 64.4,41.8 62,40.3 L54.3,48 L54.3,26.7 C54.3,17.9 61.5,10.7 70.3,10.7 C79.1,10.7 86.3,17.9 86.3,26.7 C86.3,35.5 79.2,42.7 70.3,42.7 Z" fill="#4171F9"></path>
              </g>
            </svg>
          </Link>


          {/*<Link
                      to="/"
                      className={cx(Nav.link, Space.p2)}>
                      Features
                      <ChevronDown color={"black"} style={{width:14,marginLeft:5}} />
                    </Link>

          <Link
            to="/use-cases"
            className={cx(Nav.link, Space.p2, Flex.hcc)}>
            Your Use Case
          </Link>*/}

        </div>
        <div>
          <SignUpButton />
        </div>
      </header>
    )
  } else if (headerType === "minimal") {
    return (
      <header className={cx(Nav.navbar, Flex.hcj, Space.pl4, Space.pr4)}>
        <Link to="/">
          <img src="img/dlc.svg" style={{height:37,marginRight:25,marginBottom:5}} />
        </Link>
        <div>
          <SignUpButton />
        </div>
      </header>
    )
  } else if (headerType === "none") {
    return null;
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
