import { Link } from "gatsby"
import React from "react"
import { cx } from "emotion"
import { Background, Space, Flex, Typo, Links, Btn } from "../styles"
import { Row, Col } from "reactstrap"

import { DeeploopOcta, Phone, Mail, ArrowRight } from "./icons"
import SignUpForm from "./forms/signup"

const Footer = ({
  hasCta = true
}) => (
  <footer>
    {/* CTA */}
    { hasCta && (
      <div className={cx(Background.grey, Space.section)}>
        <Row>
          <Col xs={12} sm={8} md={6}>
            <h1>Getting started is easy</h1>
            <p>Discover how you can solve inbound sales today and start selling like a team twice your size.</p>
            <SignUpForm />
          </Col>
        </Row>
      </div>
    )}
    {/* MENU */}
    <div className={cx(Background.dark,Space.smallSection, Typo.reg)}>
      <Row>
        <Col sm="12" sm="6" md="8" className={cx(Space.mb3)}>
          <DeeploopOcta style={{height:40,width:40}}/>
        </Col>
        {/*<Col sm="12" sm="4"md="2" className={cx(Space.mb3)}>
          <div><b>Product</b></div>
          <div>
            <ul>
              <li>Link 1</li>
              <li>Link 2</li>
            </ul>
          </div>
        </Col>*/}
        <Col sm="12" sm="6" md="4" className={cx(Space.mb3)}>
          <div><b>Contact</b></div>
          <div>
            <a className={cx(Links.onDark,Flex.hcl,Space.mb1,Space.mt1)} href="tel:+18883033390"><Phone style={{width:14,height:14,marginRight:10}}/>+1 888 303 33 90</a>
          </div>
          <div>
            <a className={cx(Links.onDark,Flex.hcl,Space.mb1,Space.mt1)} href="mailto:sales@deeploop.com"><Mail style={{width:14,height:14,marginRight:10}}/>sales@deeploop.com</a>
          </div>
          <div className={cx(Space.mt3)}>
            <a href="https://deeploop.com" target="_blank">
              <button className={cx(Btn.reset, Btn.primary, Space.pl3, Space.pr3, Space.ml1, Flex.hcc)} style={{height: 50,lineHeight:"1em",width:"100%"}}>Open Deeploop.com <ArrowRight style={{height:15,marginLeft:10}}/></button>
            </a>
          </div>
        </Col>
      </Row>
    </div>
    {/* Notices */}
    <div className={cx(Background.dark2,Space.smallSection, Flex.hcj)}>
      <div className={cx(Typo.small, Typo.darkmuted)}>© 2019 Deeploop, Inc.</div>
      <div className={cx(Typo.small, Typo.darkmuted) + " " + cx(Flex.hcc)}>
        <Link to="/terms-of-service" className={cx(Space.mr2, Space.ml2, Links.onDark)}>
          Terms of Service
        </Link>
        <Link to="/privacy-policy" className={cx(Space.mr2, Space.ml2, Links.onDark)}>
          Privacy Policy
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer