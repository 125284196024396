import { css } from 'emotion'

export const ImageClasses = {
  greyClick: css`
    cursor:pointer;
    filter:grayscale(1) brightness(1.4) contrast(0.1);
    transition:0.3s ease-in-out;
    &:hover {
      transition:0.3s ease-in-out;
      filter:grayscale(1) brightness(1) contrast(1);
    }
    &.active {
      transition:0.3s ease-in-out;
      filter:grayscale(1) brightness(1) contrast(1);
    }
  `
}