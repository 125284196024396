import { css } from 'emotion';
import { Colors, Transition } from './constants';
import { Spacing } from './spacing';
import { FlexClasses } from './flex';
import { CardClasses } from './card';
import { TypoClasses } from './typography';
import { BorderClasses } from './border';
import { ButtonClasses } from './button';
import { FormClasses } from './Form';
import { LayoutClasses } from './Layout';
import { VanityClasses } from './Vanity';
import { GridClasses } from './Grid';
import { MapStyleClasses } from './MapStyles';
import { TagClasses } from './Tag';
import { ImageClasses } from './Image';
import { LinkStyles } from './Links';
import { TransitionClasses } from './Transitions';
import { InteractiveClasses } from './Interactive';
import { SpeechClasses } from './Speech';

export const Flex = FlexClasses;
export const Card = CardClasses;
export const Typo = TypoClasses;
export const Border = BorderClasses;
export const Btn = ButtonClasses;
export const Form = FormClasses;
export const Layout = LayoutClasses;
export const Vanity = VanityClasses;
export const Grid = GridClasses;
export const MapStyles = MapStyleClasses;
export const Tag = TagClasses;
export const Img = ImageClasses;
export const SiteColors = Colors;
export const Links = LinkStyles;
export const Transitions = TransitionClasses;
export const Interactive = InteractiveClasses;
export const Speech = SpeechClasses;

export const Button = {
  primary: css({

  })
}

export const Space = Spacing

export const Background = {
  dark: css`
    background-color: ${Colors.dark.background};
    color: ${Colors.dark.text};
  `,
  dark2: css`
    background-color: ${Colors.dark.altBackground};
    color: ${Colors.dark.text};
  `,
  grey: css`
    background-color: ${Colors.grey.background};
  `,
  orange: css`
    background-color: ${Colors.orange.background};
  `,
  red: css`
    background-color: ${Colors.red.background};
  `,
  yellow: css`
    background-color: ${Colors.yellow.background};
  `,
  green: css`
    background-color: ${Colors.green.background};
  `,
  tint2: css`
    background-color: ${Colors.background.tint2};
  `,
  tint3: css`
    background-color: ${Colors.background.tint3};
  `,
  tint4: css`
    background-color: ${Colors.background.tint4};
  `,
  tint5: css`
    background-color: ${Colors.background.tint5};
  `,
  tint6: css`
    background-color: ${Colors.background.tint6};
  `,
  tint7: css`
    background-color: ${Colors.background.tint7};
  `,
  primary: css`
    background-color: ${Colors.primary.text};
    color: white;
  `,
  white: css`
    background-color: white;
  `
}

export const Nav = {
  navbar: css`
    position:absolute;
    height:80px;
    width:100%;
    left:0px:
    top:0px;
    right:0px;
    z-index:999;
    background-color:transparent;
  `,
  left: css`
    width:70px;
    position:fixed;
    left:0;
    top:0;
    bottom:0;
  `,
  link: css`
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.grey.faded};
    background-color: transparent;
    margin-right: 5px;
    transition: ${Transition.link};
    &:hover, &:focus {
      color: ${Colors.text.default};
      box-shadow: none;
    }
  `,
  linkSelected: css`
    background-color: rgba(255,255,255,0.1);
    color: ${Colors.green.text};
    &:focus {
      background-color: rgba(255,255,255,0.1);
      color: ${Colors.green.text};
    }
    &:hover {
      background-color: ${Colors.green.text};
      color: white;
    }
  `
}
