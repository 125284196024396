import { css } from "emotion"

export const TransitionClasses = {
  fadeInX: css`
    transform: translateX(60px);
    opacity: 0;
    transition: 0.7s ease-out;
    &.isShown {
      transform: translateX(0px);
      opacity: 1;
    }
  `
}