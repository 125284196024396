import { css } from 'emotion'

export const LayoutClasses = {
  container: css`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    
    @media (min-width: 768px) {
      .container {
        width: 750px;
      }
    }
    @media (min-width: 992px) {
      .container {
        width: 970px;
      }
    }
    @media (min-width: 1200px) {
      .container {
        width: 1170px;
      }
    }
  `,
  h60: css`
    width: 60%;
    @media (min-width: 768px) {
      .h60 {
        width: 100%;
      }
    }
  `,
  h40: css`
    width: 40%;
    @media (min-width: 768px) {
      .h40 {
        width: 100%;
      }
    }
  `,
  card33: css`
    width: 33%;
    position: absolute;
    right: 50px;
    bottom: 100px;
    height: 100%;
    max-height:400px;
    min-height:300px;
    z-index:99;

    @media (max-width: 768px) {
      width: 90%;
      left:5%;
      right:5%;
      bottom: auto;
      top: -175px;
      height: 350px !important;
    }

  `
}